import { HttpGetPrefectureService } from "@data/services/prefecture";
import { HttpGetConsolidatedReportService } from "@data/services/consolidated-report";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { ConsolidatedReportPresenter } from "@presentation/presenters/consolidated-report";
import { ConsolidatedReportPresenterContract } from "@ui/presenters/consolidated-report";

export const makeConsolidatedReportPresenter = (): ConsolidatedReportPresenterContract => {

    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const getConsolidatedReportService = new HttpGetConsolidatedReportService(httpRepository);
    const getPrefecturesService = new HttpGetPrefectureService(httpRepository);

    return new ConsolidatedReportPresenter(
        getConsolidatedReportService,
        getPrefecturesService
    );
};
