import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { UploadPlanningBillsService } from "@domain/services/bills";

export class HttpUploadPlanningBillsService
  implements UploadPlanningBillsService
{
  constructor(private readonly httpRepository: HttpRepository) {}

  async handle(params: UploadPlanningBillsService.Params): Promise<void> {
    const formData = new FormData();
    formData.append("file", params.file);

    const request = HttpRequestBuilder.requestToUrl(
      "/spreadsheet/nonEffectiveBills"
    )
      .withMethod(HttpRequestTypes.POST)
      .withData(params)
      .withHeaders({ "Content-Type": "multipart/form-data" })
      .build();

    await this.httpRepository.makeRequest<any>(request);
  }
}
