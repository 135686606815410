import { makeChangeCityPresenter } from "@main/factories/presenters/users";
import { ChangeCityPresenterContract } from "@ui/presenters/users";
import ChangeCityView from "@ui/views/users/ChangeCityView";
import React, { useMemo } from "react";

interface ChangeCityProps {
    cityToChange: ChangeCityPresenterContract.Payload | null;
    onDismiss(): void;
}

export const ChangeCity: React.FC<ChangeCityProps> = (props) => {
    const changeCityPresenter = useMemo(() => makeChangeCityPresenter(), []);

    return <ChangeCityView {...props} changeCityPresenter={changeCityPresenter} />;
};

export default ChangeCity;
