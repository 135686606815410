import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { ChangeCityService } from "@domain/services/users";

export class HttpChangeCityService implements ChangeCityService {
    constructor(
      private readonly httpRepository: HttpRepository
    ) { }
  
    async handle(payload: ChangeCityService.Payload): Promise<boolean> {
      const httpRequest = HttpRequestBuilder.requestToUrl('/users/changeCity')
        .withMethod(HttpRequestTypes.PUT)
        .withData(payload)
        .build();
  
      const { status } = await this.httpRepository.makeRequest<ChangeCityService>(httpRequest);
  
      return status === 200;
    }
  }
