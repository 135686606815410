import { format, parseISO } from "date-fns";

export class DateUtil {
  static todayString() {
    return format(new Date(), "yyyy-MM-dd");
  }

  static formatDate(date: Date, dateFormat: string = 'yyyy-MM-dd'): string {
    return format(date, dateFormat);
  }

  static formatStringDate(date: string, dateFormat: string = 'yyyy-MM-dd'): string {
    return format(parseISO(date), dateFormat);
  }

  static toISOFormat(date: string): Date {
    return parseISO(date);
  }
}
