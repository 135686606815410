import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Alert } from "reactstrap";

import { TreasuryHourPresenterContract } from "@ui/presenters/treasury";
import { TreasuryHourIndicator } from "@ui/utils/treasury";
import { DateUtil } from "@ui/utils";

interface TreasuryHourAlertProps {
    t: any;
    treasuryHourPresenter: TreasuryHourPresenterContract;
}

const EXPECTED_MINUTES_BEFORE = 30;

const TreasuryHourAlertView: React.FC<TreasuryHourAlertProps> = ({ t, treasuryHourPresenter }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hour, setHourList] = useState<string>("");
    const [message, setMessage] = useState("");
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        treasuryHourPresenter.setView({
            setHour,
            setLoading
        });

        treasuryHourPresenter.getHour();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const isTimeToAlert = checkIfTimeToAlert();

            if (isTimeToAlert) {
                setIsOpen(true);
                setMessage(t("treasury.hour.message"));
                clearInterval(interval);
            }
        }, 1000 * 60);

        return () => clearInterval(interval);
    }, [hour]);

    const onDismiss = () => setIsOpen(false);
    const setLoading = (isLoading: boolean) => setIsLoading(isLoading);
    const setHour = (hour: string) => setHourList(hour);

    const checkIfTimeToAlert = () => {
        if (hour) {
            const nowInTime = DateUtil.formatDate(new Date(), "HH:mm");

            const isTimeToAlert = TreasuryHourIndicator.isExpectedMinutesBefore(nowInTime, hour, EXPECTED_MINUTES_BEFORE);

            return isTimeToAlert;
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={onDismiss}
            scrollable={true}
            id="staticBackdrop"
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title">{t("treasury.hour.title")}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={onDismiss}
                    aria-label="Close"
                />
            </div>

            <div className="modal-body">
                <Alert color="warning">{message}</Alert>
            </div>

            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-danger me-2"
                    onClick={onDismiss}
                    disabled={isLoading}
                >
                    {t("Close")}
                </button>
            </div>
        </Modal>
    );

};

export default withTranslation()(TreasuryHourAlertView);
