import React, { useMemo } from "react";

import { ConsolidatedReportView } from "@ui/views/consolidated-report";
import { makeConsolidatedReportPresenter } from "@main/factories/presenters/consolidated-report";

const ConsolidatedReport: React.FC = () => {
    const consolidatedReportPresenter = useMemo(() => makeConsolidatedReportPresenter(), []);

    return <ConsolidatedReportView consolidatedReportPresenter={consolidatedReportPresenter} />;
};

export default ConsolidatedReport;
