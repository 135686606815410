import { GetTreasuryHourService } from "@domain/services/treasury";
import { HttpRepository } from "@data/repositories";
import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";

export class HttpGetTreasuryHourService implements GetTreasuryHourService {
    constructor(private readonly httpRepository: HttpRepository) { }

    async handle(): Promise<GetTreasuryHourService.Response> {
        const request = HttpRequestBuilder
            .requestToUrl("/treasury/hour")
            .withMethod(HttpRequestTypes.GET)
            .build();

        const { data } = await this.httpRepository.makeRequest<any, GetTreasuryHourService.Response>(request);

        return data;
    }
}
