import { Prefecture } from "@domain/entities";
import { UserEvents } from "@domain/events";
import { ChangeCityService } from "@domain/services/users";
import { GetPrefectureService } from "@domain/services/prefecture";
import { FormValidation } from "@presentation/validation/interfaces";
import { ChangeCityViewContract } from "@presentation/views/users";
import { ChangeCityPresenterContract } from "@ui/presenters/users";
import { EventEmitter } from "@utils/events";

export class ChangeCityPresenter implements ChangeCityPresenterContract {
    private view!: ChangeCityViewContract;

    constructor(
        private readonly validation: FormValidation,
        private readonly changeCityService: ChangeCityService,
        private readonly getPrefectureService: GetPrefectureService
    ) { }

    setView(view: ChangeCityViewContract): void {
        this.view = view;
    }

    async change(payload: ChangeCityPresenterContract.Payload): Promise<boolean> {
        this.view.setLoading(true);

        const validationSuccessfull = this.validation.allValid(payload);

        if (!validationSuccessfull) {
            this.view.updateValidationState(this.validation.errors(payload));
            this.view.setLoading(false);
            return false;
        }

        const isCityChanged = await this.changeCityService.handle(payload);

        if (isCityChanged) {
            EventEmitter.emit(UserEvents.CITY_UPDATED);
        }

        this.view.updateValidationState([]);
        this.view.setLoading(false);

        return isCityChanged;
    }


    async getPrefecture(): Promise<Prefecture[]> {
        const states = await this.getPrefectureService.handle();
        this.view.setPrefectures(states);
        return states;
    }
}
