import { TreasuryHourPresenterContract } from "@ui/presenters/treasury";
import { TreasuryHourViewContract } from "@presentation/views/treasury";
import { GetTreasuryHourService } from "@domain/services/treasury";

export class TreasuryHourPresenter implements TreasuryHourPresenterContract {
    private view!: TreasuryHourViewContract;
    constructor(
        private readonly getTreasuryHourService: GetTreasuryHourService
    ) { }

    setView(view: TreasuryHourViewContract): void {
        this.view = view;
    }

    async getHour(): Promise<TreasuryHourPresenterContract.Response> {
        this.view.setLoading(true);

        const response = await this.getTreasuryHourService.handle();

        this.view.setLoading(false);
        this.view.setHour(response.hour);
        return response;
    }
}
