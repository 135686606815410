import { ChangeCityPresenterContract } from "@ui/presenters/users";
import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { ChangeCityPresenter } from "@presentation/presenters/users";
import { HttpChangeCityService } from "@data/services/users";
import { HttpGetPrefectureService } from "@data/services/prefecture";

export const makeChangeCityPresenter = (): ChangeCityPresenterContract => {
    const validation = new ValidationComposite([
        ...FieldValidationBuilder.field('prefectureId').required().build(),
        ...FieldValidationBuilder.field('oldPrefectureId').required().build(),
    ]);

    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const changeCityService = new HttpChangeCityService(httpRepository);
    const getPrefectureService = new HttpGetPrefectureService(httpRepository);

    return new ChangeCityPresenter(
        validation,
        changeCityService,
        getPrefectureService
    );
};
