import { UploadPlanningBillsService } from "@domain/services/bills";
import { UploadPlanningBillsViewContract } from "@presentation/views/bills";
import { UploadPlanningBillsPresenterContract } from "@ui/presenters/bills";

export class UploadPlanningBillsPresenter
  implements UploadPlanningBillsPresenterContract
{
  private view!: UploadPlanningBillsViewContract;

  constructor(
    private readonly uploadPlanningBillsService: UploadPlanningBillsService
  ) {}

  async upload(
    params: UploadPlanningBillsPresenterContract.Params
  ): Promise<void> {
    this.view.setLoading(true);

    await this.uploadPlanningBillsService.handle(params);

    this.view.setLoading(false);
  }

  setView(view: UploadPlanningBillsViewContract): void {
    this.view = view;
  }
}
