import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, Table } from "reactstrap";

import Loading from "@ui/components/Loading";
import logo from "@ui/assets/images/logo.jpg";
import SearchForm from "@ui/components/consolidated-report/SearchForm";

import { ConsolidatedReportPresenterContract } from "@ui/presenters/consolidated-report";
import { Breadcrumbs } from "@ui/components/Common";
import { GroupedPlanning, Prefecture } from "@domain/entities";

import { withTranslation } from "react-i18next";
import { DateUtil } from "@ui/utils";
import { DateFormatStrategy, MoneyFormatStrategy, PercentFormatStrategy } from "@ui/strategy";
import { ConsolidatedHelper } from "@ui/utils/consolidated-report";

interface ConsolidatedReportViewProps {
    t: any;
    consolidatedReportPresenter: ConsolidatedReportPresenterContract;
}

const ConsolidatedReportView: React.FC<ConsolidatedReportViewProps> = ({ t, consolidatedReportPresenter }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [activeCashflowId, setActiveCashflowId] = useState<string>("");
    const [prefectures, setPrefecturesList] = useState<Prefecture[]>([]);
    const [groupedPlanning, setGroupedPlanningsList] = useState<GroupedPlanning[]>([]);
    const [params, setParamsList] = useState<ConsolidatedReportPresenterContract.Params>({
        prefectureId: 0,
        startDate: DateUtil.todayString(),
        endDate: DateUtil.todayString(),
    });

    useEffect(() => {
        consolidatedReportPresenter.setView({
            setLoadingState,
            setPrefectures,
            setGroupedPlannings,
            setParams
        });

        consolidatedReportPresenter.get(params);
        consolidatedReportPresenter.getPrefectures();
    }, []);

    const setLoadingState = (isLoading: boolean): void => setLoading(isLoading);
    const setPrefectures = (prefectures: Prefecture[]): void => setPrefecturesList(prefectures);
    const setGroupedPlannings = (groupedPlanning: GroupedPlanning[]): void => setGroupedPlanningsList(groupedPlanning);
    const setParams = (params: ConsolidatedReportPresenterContract.Params): void => setParamsList(params);


    const transactionTypes =
        groupedPlanning.find(({ id }) => id === activeCashflowId)?.transactionTypes || [];

    let grandTotalEffectiveSum = 0;
    let grandTotalNonEffectiveSum = 0;

    transactionTypes.forEach((transactionType) => {
        transactionType.groups.forEach((group) => {
            group.subgroups.forEach((subgroup) => {
                grandTotalEffectiveSum += subgroup.effectiveSum;
                grandTotalNonEffectiveSum += subgroup.nonEffectiveSum;
            });
        });
    });

    const totalsByTransactionType: Record<string, {
        totalNonEffectiveSum: number;
        totalEffectiveSum: number;
        totalDivergence: number;
        totalDivergencePercentage: number;
    }> = {};

    transactionTypes.forEach((transactionType) => {
        const totalEffectiveSum = transactionType.groups.reduce((total, group) =>
            total + group.subgroups.reduce((subTotal, subgroup) => subTotal + subgroup.effectiveSum, 0), 0);

        const totalNonEffectiveSum = transactionType.groups.reduce((total, group) =>
            total + group.subgroups.reduce((subTotal, subgroup) => subTotal + subgroup.nonEffectiveSum, 0), 0);

        const totalDivergence = ConsolidatedHelper.calculateDivergence(totalEffectiveSum, totalNonEffectiveSum);
        const totalDivergencePercentage = ConsolidatedHelper.calculatePercent(totalEffectiveSum, totalNonEffectiveSum);

        totalsByTransactionType[transactionType.id] = {
            totalNonEffectiveSum,
            totalEffectiveSum,
            totalDivergence,
            totalDivergencePercentage
        };
    });

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs breadcrumbItem={t("Consolidated report")} title={t("Registrations")} />
                <Row>
                    {loading
                        ? (
                            <Col xl={12} className="mt-4">
                                <Loading />
                            </Col>
                        ) : (
                            <Col xl={12} className="mt-4">
                                <SearchForm
                                    onSubmit={(params: ConsolidatedReportPresenterContract.Params) => {
                                        consolidatedReportPresenter.get(params);
                                        consolidatedReportPresenter.getPrefectures();
                                    }}
                                    prefectures={prefectures}
                                />

                                <Card>
                                    <CardBody>
                                        <Nav className="nav-tabs-custom card-header-tabs">
                                            {groupedPlanning.map(({ name, id }) => (
                                                <NavItem key={id}>
                                                    <NavLink
                                                        to="#"
                                                        className={`px-3 nav-link ${activeCashflowId === id ? "active" : ""}`}
                                                        onClick={() => setActiveCashflowId(id)}
                                                    >
                                                        {name}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>
                                    </CardBody>
                                </Card>

                                <div className="table-responsive text-center font-arial-rlt">
                                    <img
                                        style={{ objectFit: "contain" }}
                                        src={logo}
                                        height="150"
                                        width="150"
                                    />
                                    <h3>{t("Consolidated report")}</h3>
                                    <h6>{t("Period")}: { }
                                        {DateFormatStrategy.format(params.startDate)} - {DateFormatStrategy.format(params.endDate)}
                                    </h6>
                                </div>

                                <div className="table-responsive">

                                    <Fragment>
                                        <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                                            <h4>{t("General resource")}</h4>
                                        </div>

                                        <Table className="align-middle mb-4" bordered>
                                            <thead className="font-arial-rlt">
                                                <tr>
                                                    <th style={{ width: "45%" }}>{t("Description")}</th>
                                                    <th className="text-center">{t("Foreseen")}</th>
                                                    <th className="text-center">{t("Realized")}</th>
                                                    <th className="text-center">{t("Divergence")} ($)</th>
                                                    <th className="text-center" style={{ width: "15%" }}>{t("Divergence")} (%)</th>
                                                </tr>
                                            </thead>
                                            {transactionTypes.map((transactionType) => {
                                                const totalEffectiveSum = transactionType.groups.reduce((total, group) =>
                                                    total + group.subgroups
                                                        .reduce((subTotal, subgroup) => subTotal + subgroup.effectiveSum, 0), 0);

                                                const totalNonEffectiveSum = transactionType.groups.reduce((total, group) =>
                                                    total + group.subgroups
                                                        .reduce((subTotal, subgroup) => subTotal + subgroup.nonEffectiveSum, 0), 0);

                                                return (
                                                    <tbody className="font-arial-rlt">
                                                        <tr>
                                                            <td>{transactionType.name} {t("Total")}</td>
                                                            <td className="text-center">
                                                                {MoneyFormatStrategy.format(totalNonEffectiveSum)}
                                                            </td>
                                                            <td className="text-center">
                                                                {MoneyFormatStrategy.format(
                                                                    ConsolidatedHelper.isNegative(totalEffectiveSum)
                                                                )}

                                                            </td>
                                                            <td className="text-center">
                                                                {MoneyFormatStrategy.format(
                                                                    ConsolidatedHelper.calculateDivergence(
                                                                        totalEffectiveSum, totalNonEffectiveSum
                                                                    ))}
                                                            </td>
                                                            <td className="text-center">
                                                                {Number.isNaN(ConsolidatedHelper.calculatePercent(
                                                                    totalEffectiveSum, totalNonEffectiveSum
                                                                )) ?
                                                                PercentFormatStrategy.format(0) :
                                                                PercentFormatStrategy.format(
                                                                    ConsolidatedHelper.calculatePercent(
                                                                        totalEffectiveSum, totalNonEffectiveSum
                                                                    ))}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                );
                                            })}
                                            <tfoot className="font-arial-rlt">
                                                <tr>
                                                    <th>{t("consolidated.total.mouth")}</th>
                                                    <th className="text-center">
                                                        {MoneyFormatStrategy.format(grandTotalNonEffectiveSum)}
                                                    </th>
                                                    <th className="text-center">
                                                        {MoneyFormatStrategy.format(
                                                            ConsolidatedHelper.isNegative(grandTotalEffectiveSum)
                                                        )}
                                                    </th>
                                                    <th className="text-center">
                                                        {MoneyFormatStrategy.format(
                                                            ConsolidatedHelper.calculateDivergence(
                                                                grandTotalEffectiveSum, grandTotalNonEffectiveSum
                                                            ))}
                                                    </th>
                                                    <th className="text-center">
                                                        {Number.isNaN(ConsolidatedHelper.calculatePercent(
                                                            grandTotalEffectiveSum, grandTotalNonEffectiveSum
                                                        )) ?
                                                            PercentFormatStrategy.format(0) :
                                                            PercentFormatStrategy.format(
                                                                ConsolidatedHelper.calculatePercent(
                                                                    grandTotalEffectiveSum, grandTotalNonEffectiveSum
                                                                ))
                                                        }
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Fragment>

                                    {transactionTypes.map((transactionType) => (
                                        <Fragment key={transactionType.id}>
                                            <div className="d-sm-flex flex-column align-items-center justify-content-center mt-5 mb-3">
                                                <h4>{transactionType.name}</h4>
                                            </div>

                                            <Table className="align-middle mb-4 font-arial-rlt" bordered>
                                                {transactionType.groups.map(({ id, name, subgroups }) => {
                                                    const filteredSubgroups =
                                                        subgroups.filter((subgroup) => subgroup.effectiveSum !== 0 ||
                                                            subgroup.nonEffectiveSum !== 0);

                                                    if (filteredSubgroups.length === 0) return null;

                                                    const totalEffectiveSum = filteredSubgroups
                                                        .reduce((prev, current) => prev + current.effectiveSum, 0);

                                                    const totalNonEffectiveSum = filteredSubgroups
                                                        .reduce((prev, current) => prev + current.nonEffectiveSum, 0);

                                                    return (
                                                        <Fragment key={id}>
                                                            <thead>
                                                                <tr>
                                                                    <th>{name}</th>
                                                                    <th className="text-center">{t("Foreseen")}</th>
                                                                    <th className="text-center">{t("Realized")}</th>
                                                                    <th className="text-center">{t("Divergence")} ($)</th>
                                                                    <th className="text-center">{t("Divergence")} (%)</th>
                                                                </tr>
                                                            </thead>
                                                            {filteredSubgroups.map(({ id, name, effectiveSum, nonEffectiveSum }) => (
                                                                <tbody key={id}>
                                                                    <tr>
                                                                        <td style={{ width: "45%" }}>{name}</td>
                                                                        <td className="text-center">
                                                                            {MoneyFormatStrategy.format(nonEffectiveSum)}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {MoneyFormatStrategy.format(
                                                                                ConsolidatedHelper.isNegative(effectiveSum)
                                                                            )}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {transactionType.name === "Despesa" ?
                                                                                MoneyFormatStrategy.format(
                                                                                    ConsolidatedHelper.isNegative(ConsolidatedHelper.calculateDivergence(
                                                                                        effectiveSum, nonEffectiveSum
                                                                                    ))) :
                                                                                MoneyFormatStrategy.format(
                                                                                    ConsolidatedHelper.calculateDivergence(
                                                                                        effectiveSum, nonEffectiveSum
                                                                                    ))}
                                                                        </td>
                                                                        <td className="text-center" style={{ width: "15%" }}>
                                                                            {PercentFormatStrategy.format(
                                                                                ConsolidatedHelper.calculatePercent(
                                                                                    effectiveSum, nonEffectiveSum
                                                                                ))}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ))}
                                                            <tbody className="font-arial-rlt">
                                                                <tr>
                                                                    <th>{t("Total")}</th>
                                                                    <th className="text-center">
                                                                        {MoneyFormatStrategy.format(totalNonEffectiveSum)}
                                                                    </th>
                                                                    <th className="text-center">
                                                                        {MoneyFormatStrategy.format(
                                                                            ConsolidatedHelper.isNegative(totalEffectiveSum)
                                                                        )}
                                                                    </th>
                                                                    <th className="text-center">
                                                                        {transactionType.name === "Despesa" ?
                                                                            MoneyFormatStrategy.format(
                                                                                ConsolidatedHelper.isNegative(ConsolidatedHelper.calculateDivergence(
                                                                                    totalEffectiveSum, totalNonEffectiveSum
                                                                                ))) :
                                                                            MoneyFormatStrategy.format(
                                                                                ConsolidatedHelper.calculateDivergence(
                                                                                    totalEffectiveSum, totalNonEffectiveSum
                                                                                ))}
                                                                    </th>
                                                                    <th className="text-center">
                                                                        {PercentFormatStrategy.format(
                                                                            ConsolidatedHelper.calculatePercent(
                                                                                totalEffectiveSum, totalNonEffectiveSum
                                                                            ))}
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </Fragment>
                                                    );
                                                })}
                                                <tfoot className="font-arial-rlt">
                                                    <tr>
                                                        <th style={{ width: "45%" }}>
                                                            {t("consolidated.total.title")} {transactionType.name}
                                                        </th>
                                                        <th className="text-center">
                                                            {MoneyFormatStrategy.format(
                                                                totalsByTransactionType[transactionType.id]?.totalNonEffectiveSum || 0
                                                            )}
                                                        </th>
                                                        <th className="text-center">
                                                            {MoneyFormatStrategy.format(
                                                                ConsolidatedHelper.isNegative(
                                                                    totalsByTransactionType[transactionType.id]?.totalEffectiveSum || 0
                                                                ))}
                                                        </th>
                                                        <th className="text-center"
                                                        >{transactionType.name === "Despesa" ?
                                                            MoneyFormatStrategy.format(
                                                                ConsolidatedHelper.isNegative(totalsByTransactionType[transactionType.id]?.totalDivergence || 0)
                                                            ) :
                                                            MoneyFormatStrategy.format(
                                                                totalsByTransactionType[transactionType.id]?.totalDivergence || 0
                                                            )
                                                            }
                                                        </th>
                                                        <th className="text-center" style={{ width: "15%" }}>
                                                            {Number.isNaN(totalsByTransactionType[transactionType.id]?.totalDivergencePercentage) ?
                                                                PercentFormatStrategy.format(0) :
                                                                PercentFormatStrategy.format(totalsByTransactionType[transactionType.id]?.totalDivergencePercentage || 0)
                                                            }
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Fragment>
                                    ))}
                                </div>

                            </Col>
                        )}
                </Row>
            </Container>
        </div>
    );
};

export default withTranslation()(ConsolidatedReportView);
