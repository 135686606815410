import React, { Fragment, useState } from "react";

import { Card, CardBody, Col, Label, Row } from "reactstrap";

import { withTranslation } from "react-i18next";

import { FieldValidationBuilder } from "@presentation/validation/builders";
import { ValidationComposite } from "@presentation/validation/validators";
import { useValidation } from "@ui/hooks";
import { Prefecture } from "@domain/entities";
import { ConsolidatedReportPresenterContract } from "@ui/presenters/consolidated-report";
import { SelectItem } from "@ui/interfaces";

interface SearchFormProps {
    t: any;
    prefectures: Prefecture[];
    onSubmit(params: ConsolidatedReportPresenterContract.Params): void;
}

const validation = new ValidationComposite([
    ...FieldValidationBuilder.field('prefectureId').required().build(),
    ...FieldValidationBuilder.field('startDate').required().build(),
    ...FieldValidationBuilder.field('endDate').required().build(),
]);

const SearchForm: React.FC<SearchFormProps> = ({ t, prefectures, onSubmit }) => {
    const [params, setParams] = useState<ConsolidatedReportPresenterContract.Params>({
        prefectureId: 0,
        startDate: "",
        endDate: ""
    });

    const { ValidationFeedback, validationState, ValidationInput, ValidationSelect, updateValidationState } = useValidation();

    const updateField = (fieldName: string, value: string) => {
        setParams((oldState) => ({
            ...oldState,
            [fieldName]: value
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (validation.allValid(params)) {
            updateValidationState([]);
            onSubmit(params);
        } else {
            updateValidationState(validation.errors(params));
        }
    };

    const optionsPrefecture: SelectItem[] = prefectures.map(({ id, cityName }) => ({
        label: cityName,
        value: id
    }));

    return (
        <Fragment>
            <form onSubmit={handleSubmit}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col className="mb-3" md={12} sm={12}>
                                <Label>{t("Prefecture")}</Label>

                                <ValidationSelect
                                    field="prefectureId"
                                    className="form-control"
                                    name="prefectureId"
                                    validationState={validationState}
                                    options={optionsPrefecture}
                                    placeholder={t("Select")}
                                    onChange={({ target: { name, value } }) =>
                                        updateField(name, value)
                                    }
                                />

                                <ValidationFeedback field="prefectureId" />
                            </Col>

                            <Col className="mb-3" md={6} sm={12}>
                                <Label
                                    htmlFor="example-date-input"
                                    className="form-Label"
                                >
                                    {t("Beginning of period")}
                                </Label>

                                <ValidationInput
                                    field="startDate"
                                    validationState={validationState}
                                    className="form-control"
                                    type="date"
                                    name="startDate"
                                    onChange={({ target: { name, value } }) => updateField(name, value)}
                                />

                                <ValidationFeedback field="startDate" />
                            </Col>

                            <Col className="mb-3" md={6} sm={12}>
                                <Label
                                    htmlFor="example-date-input"
                                    className="form-Label">
                                    {t("End of period")}
                                </Label>

                                <ValidationInput
                                    field="endDate"
                                    name="endDate"
                                    validationState={validationState}
                                    className="form-control"
                                    type="date"
                                    onChange={({ target: { name, value } }) => updateField(name, value)}
                                />

                                <ValidationFeedback field="endDate" />
                            </Col>

                            <Col sm={12}>
                                <div className="d-sm-flex align-items-center justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                    >
                                        {t("Search")}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </form>
        </Fragment>
    );
};

export default withTranslation()(SearchForm);
