import { useEffect, useState } from "react";

import { UploadPlanningBillsPresenterContract } from "@ui/presenters/bills";
import { useValidation } from "@ui/hooks";

import { Col, FormGroup, Modal, Row } from "reactstrap";
import { withTranslation } from "react-i18next";

interface UploadPlanningBillsViewProps {
  t: any;
  isOpen: boolean;
  uploadPlanningBillsPresenter: UploadPlanningBillsPresenterContract;
  onDismiss(): void;
}

const UploadPlanningBillsView: React.FC<UploadPlanningBillsViewProps> = ({
  t,
  isOpen,
  onDismiss,
  uploadPlanningBillsPresenter,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParamsList] =
    useState<UploadPlanningBillsPresenterContract.Params>({
      file: null,
    });

  const {
    ValidationFeedback,
    updateValidationState,
    validationState,
    ValidationInput,
  } = useValidation();

  useEffect(() => {
    uploadPlanningBillsPresenter.setView({
      setLoading,
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      updateValidationState([]);
    }
  }, [isOpen]);

  const setLoading = (isLoading: boolean) => setIsLoading(isLoading);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    uploadPlanningBillsPresenter.upload({
      file: params.file,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={isLoading ? undefined : onDismiss}
      scrollable={true}
      id="staticBackdrop"
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("bills.upload.title")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={isLoading ? undefined : onDismiss}
          aria-label="Close"
        />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <Row>
            <Col md={12} className="mb-2">
              <FormGroup>
                <label>{t("bills.upload.file")}</label>
                <ValidationInput
                  validationState={validationState}
                  field="file"
                  type="file"
                  className="form-control"
                  onChange={({ target: { files } }) => {
                    setParamsList({
                      file: files?.[0],
                    });
                  }}
                />

                <ValidationFeedback field="file" />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={isLoading ? undefined : onDismiss}
            disabled={isLoading}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" disabled={isLoading}>
            {t(isLoading ? "loading" : "Save")}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withTranslation()(UploadPlanningBillsView);
