import { HttpGetTreasuryHourService } from "@data/services/treasury/HttpGetTreasuryHourService";
import { AxiosHttpRepository } from "@infra/http";
import { API_BASE_URL } from "@main/constants/config";
import { TreasuryHourPresenter } from "@presentation/presenters/treasury/TreasuryHourPresenter";
import { TreasuryHourPresenterContract } from "@ui/presenters/treasury";

export const makeTreasuryHourPresenter = (): TreasuryHourPresenterContract => {
    const httpRepository = new AxiosHttpRepository(API_BASE_URL);
    const getTreasuryHourService = new HttpGetTreasuryHourService(httpRepository);

    return new TreasuryHourPresenter(
        getTreasuryHourService
    );
};
