import { Prefecture } from "@domain/entities";
import { useValidation } from "@ui/hooks";
import { ChangeCityPresenterContract } from "@ui/presenters/users";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Col, FormGroup, Label, Modal, Row } from "reactstrap";

interface ChangeCityViewProps {
    t: any;
    onDismiss(): void;
    cityToChange: ChangeCityPresenterContract.Payload | null;
    changeCityPresenter: ChangeCityPresenterContract;
}

const INITIAL_FORM_STATE = {
    prefectureId: 0
};

const ChangeCityView: React.FC<ChangeCityViewProps> = ({ t, cityToChange, onDismiss, changeCityPresenter }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [payload, setPayload] = useState<ChangeCityPresenterContract.Payload>(INITIAL_FORM_STATE);
    const [prefectures, setPrefecturesList] = useState<Prefecture[]>([]);

    const { ValidationFeedback, updateValidationState, ValidationSelect, validationState } = useValidation();

    useEffect(() => {
        changeCityPresenter.getPrefecture();
        changeCityPresenter.setView({ setLoading, setPrefectures, updateValidationState });
    }, []);

    useEffect(() => {
        if (!cityToChange) {
            updateValidationState([]);
            setPayload(INITIAL_FORM_STATE);
        } else {
            setPayload(cityToChange);

            changeCityPresenter.getPrefecture();
        }
    }, [cityToChange]);

    const setLoading = (isLoading: boolean): void => setIsLoading(isLoading);
    const setPrefectures = (prefectures: Prefecture[]): void => setPrefecturesList(prefectures);

    const updateField = (fieldName: string, value: string) => {
        setPayload((oldState) => ({
            ...oldState,
            [fieldName]: value
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        changeCityPresenter.change(payload);
    };

    const optionsPrefectures = prefectures.map(({ id, cityName }) => ({ value: id, label: cityName }));

    return (
        <Modal
            isOpen={Boolean(cityToChange)}
            toggle={isLoading ? undefined : onDismiss}
            scrollable={true}
            id="staticBackdrop"
            size="lg"
        >
            <div className="modal-header">
                <h5 className="modal-title">{t("user.changeCity")}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={isLoading ? undefined : onDismiss}
                    aria-label="Close"
                />
            </div>
            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    <Row>
                        <Col md={12} className="mb-2">
                            <FormGroup>
                                <Label>{t("user.changeCity.placeholder")}</Label>

                                <ValidationSelect
                                    field="prefectureId"
                                    options={optionsPrefectures}
                                    validationState={validationState}
                                    name="prefectureId"
                                    className="form-control"
                                    placeholder={t('users.add.profile.placeholder')}
                                    onChange={({ target: { value, name } }) => updateField(name, value)}
                                    value={payload.prefectureId}
                                />

                                <ValidationFeedback field="prefectureId" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger me-2"
                        onClick={isLoading ? undefined : onDismiss}
                        disabled={isLoading}
                    >
                        {t("Close")}
                    </button>

                    <button className="btn btn-primary" disabled={isLoading}>
                        {t(isLoading ? "loading" : "Save")}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default withTranslation()(ChangeCityView);
