import { Source, Provider, GroupedPlanning } from "@domain/entities";
import { BillsEvents } from "@domain/events/BillsEvents";
import { GroupsByFlowsList } from "@domain/interfaces/accounts";
import { GetBankAccountService } from "@domain/services/account/GetBankAccountService";
import {
  AddEffectiveBillsService,
  GetNonEffectiveBillsService,
} from "@domain/services/bills";
import { GetProviderService } from "@domain/services/provider";
import { GetSourcesService } from "@domain/services/sources";
import { FormValidation } from "@presentation/validation/interfaces";
import { AddEffectiveBillsViewContract } from "@presentation/views/bills";
import { AddEffectiveBillsPresenterContract } from "@ui/presenters/bills";
import { EventEmitter } from "@utils/events/EventEmitter";
import { GetGroupedPlanningService } from "@domain/services/planning";

export class AddEffectiveBillsPresenter
  implements AddEffectiveBillsPresenterContract
{
  private view!: AddEffectiveBillsViewContract;

  constructor(
    private readonly validation: FormValidation,
    private readonly addEffectiveBillsService: AddEffectiveBillsService,
    private readonly getAccountsService: GetBankAccountService,
    private readonly getSourcesService: GetSourcesService,
    private readonly getProviderService: GetProviderService,
    private readonly getPlanningService: GetGroupedPlanningService,
    private readonly getNonEffectiveBillsService: GetNonEffectiveBillsService
  ) {}

  async add(
    payload: AddEffectiveBillsPresenterContract.Payload
  ): Promise<boolean> {
    this.view.setLoading(true);

    const validationSuccessfull = this.validation.allValid(payload);

    if (!validationSuccessfull) {
      this.view.updateValidationState(this.validation.errors(payload));
      this.view.setLoading(false);
      return false;
    }

    const isBillAdded = await this.addEffectiveBillsService.handle(payload);

    if (isBillAdded) {
      EventEmitter.emit(BillsEvents.BILLS_ADDED);
    }

    this.view.updateValidationState([]);
    this.view.setLoading(false);

    return isBillAdded;
  }

  setView(view: AddEffectiveBillsViewContract): void {
    this.view = view;
  }

  async getAccounts(): Promise<GroupsByFlowsList[]> {
    const accounts = await this.getAccountsService.handle();
    this.view.setAccounts(accounts);
    return accounts;
  }

  async getSources(): Promise<Source[]> {
    const sources = await this.getSourcesService.handle();
    this.view.setSources(sources);
    return sources;
  }

  async getProvider(): Promise<Provider[]> {
    const providers = await this.getProviderService.handle();
    this.view.setProviders(providers);
    return providers;
  }

  async getPlanning(): Promise<GroupedPlanning[]> {
    const planning = await this.getPlanningService.handle();
    this.view.setPlanning(planning);
    return planning;
  }

  async getNonEffective(
    params: GetNonEffectiveBillsService.Params
  ): Promise<GetNonEffectiveBillsService.Response[]> {
    const nonEffective = await this.getNonEffectiveBillsService.handle(params);
    this.view.setNonEffective(nonEffective);
    return nonEffective;
  }
}
