export class ConsolidatedHelper {
    static isNegative(value: number): number {
        return value < 0 ?
            value * -1 :
            value;
    }

    static calculateDivergence(effective: number, nonEffective: number): number {
        return this.isNegative(effective) - nonEffective;
    }

    static calculatePercent(effective: number, nonEffective: number): number {
        return effective < 0 ? 
        ((effective * -1) - nonEffective) / effective * 100 :
        (effective - nonEffective) / nonEffective * 100;
    }
}
