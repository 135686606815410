import { HttpRequestBuilder } from "@data/builders";
import { HttpRequestTypes } from "@data/enums";
import { HttpRepository } from "@data/repositories";
import { GroupedPlanning } from "@domain/entities";
import { GetConsolidatedReportService } from "@domain/services/consolidated-report";
import { ConsolidatedReportPresenterContract } from "@ui/presenters/consolidated-report";

export class HttpGetConsolidatedReportService implements GetConsolidatedReportService {
    constructor(
        private readonly httpRepository: HttpRepository
    ) { }

    async handle(params: ConsolidatedReportPresenterContract.Params): Promise<GroupedPlanning[]> {
        const request = HttpRequestBuilder
            .requestToUrl('/reports/consolidated')
            .withMethod(HttpRequestTypes.GET)
            .withParams({
                prefectureId: params.prefectureId,
                startDate: params.startDate,
                endDate: params.endDate
            })
            .build();

        const { data } = await this.httpRepository.makeRequest<any, GroupedPlanning[]>(request);

        return data;
    }
}
