import { useMemo } from "react";

import { makeTreasuryHourPresenter } from "@main/factories/presenters/treasury";
import TreasuryHourAlertView from "@ui/views/treasury/TreasuryHourAlertView";

const TreasuryHourAlert: React.FC = () => {
    const treasuryHourPresenter = useMemo(() => makeTreasuryHourPresenter(), []);

    return <TreasuryHourAlertView treasuryHourPresenter={treasuryHourPresenter} />;
};

export default TreasuryHourAlert;
