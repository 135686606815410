import { PercentFormat } from "@ui/interfaces";

export class PercentFormatDecorator implements PercentFormat {
   constructor(
      private readonly percentFormat: PercentFormat,
   ) { }

   handle(value: number): string {
      return this.percentFormat.handle(value) + "%";
   }
}
