import { differenceInMinutes, isAfter } from "date-fns";
import { DateUtil } from "../DateUtil";

export class TreasuryHourIndicator {
    static isExpectedMinutesBefore(now: string, hour: string, minutesDifference: number) {
        const date = DateUtil.formatDate(new Date(), 'yyyy-MM-dd');
        const nowDate = DateUtil.formatStringDate(`${date} ${now}`, 'yyyy-MM-dd HH:mm');
        const dateToCheck = DateUtil.formatStringDate(`${date} ${hour}`, 'yyyy-MM-dd HH:mm');

        const nowDateISO = DateUtil.toISOFormat(nowDate);
        const dateToCheckISO = DateUtil.toISOFormat(dateToCheck);

        if (isAfter(nowDateISO, dateToCheckISO)) {
            return false;
        }

        return differenceInMinutes(nowDateISO, dateToCheckISO) <= minutesDifference;
    }
}
