import { GroupedPlanning, Prefecture } from "@domain/entities";
import { GetPrefectureService } from "@domain/services/prefecture";
import { GetConsolidatedReportService } from "@domain/services/consolidated-report";
import { ConsolidatedReportViewContract } from "@presentation/views/consolidated-report";
import { ConsolidatedReportPresenterContract } from "@ui/presenters/consolidated-report";

export class ConsolidatedReportPresenter implements ConsolidatedReportPresenterContract {
    private view!: ConsolidatedReportViewContract;

    constructor(
        private readonly getConsolidatedReportService: GetConsolidatedReportService,
        private readonly getPrefecturesService: GetPrefectureService
    ) { }

    setView(view: ConsolidatedReportViewContract): void {
        this.view = view;
    }

    async get(params: ConsolidatedReportPresenterContract.Params): Promise<GroupedPlanning[]> {
        this.view.setLoadingState(true);

        const consolidatedReport = await this.getConsolidatedReportService.handle(params);

        this.view.setParams(params);
        this.view.setGroupedPlannings(consolidatedReport);
        this.view.setLoadingState(false);

        return consolidatedReport;
    }

    async getPrefectures(): Promise<Prefecture[]> {
        const prefectures = await this.getPrefecturesService.handle();
        this.view.setPrefectures(prefectures);

        return prefectures;
    }
}
